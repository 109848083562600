<template>
  <ion-page>
    <ion-content ref="mainContent" :fullscreen="true" :scroll-events="true" @ionScroll="goScroll($event)" style="background:#131a21;--background:#131a21;">
    <header>
			<div @click="goToLink('/')" href="" class="logo" style="cursor:pointer">
				<img src="../img/logo.svg" alt="logo" />
			</div>
			<div class="header-links" :class="classBurger">
				<p @click="goToLink('about')" style="cursor:pointer">About</p>
				<p @click="goToLink('support')" style="cursor:pointer">Support</p>
				<span class="close-menu" id="closeHome">×</span>
			</div>
			<div class="lang">
				<select @change="goLang($event)" id="langSel">
					<option datalang="EN" selected value="EN">English</option>
					<option datalang="RU" value="RU">Русский</option>
				</select>
				<svg
					width="28px"
					height="28px"
					viewBox="0 0 19 20"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Laguage-selector-Tests"
						stroke="none"
						stroke-width="1"
						fill="none"
						fill-rule="evenodd"
					>
						<g
							id="Contact-sales_update-mock"
							transform="translate(-1082.000000, -552.000000)"
						>
							<g
								id="Nav-Copy-3"
								transform="translate(60.000000, 534.000000)"
							>
								<polygon
									id="Nav-bg"
									fill="none"
									points="0 0 1440 0 1440 56 0 56"
								></polygon>
								<g
									id="globe"
									transform="translate(1022.000000, 18.500000)"
									fill="#F2F7FF"
									fill-rule="nonzero"
								>
									<path
										d="M9.5,0 C4.275,0 0,4.275 0,9.5 C0,14.725 4.275,19 9.5,19 C14.725,19 19,14.725 19,9.5 C19,4.275 14.725,0 9.5,0 Z M16.98125,5.9375 L13.359375,5.9375 C13.0625,4.096875 12.528125,2.553125 11.815625,1.54375 C14.13125,2.196875 15.971875,3.8 16.98125,5.9375 Z M12.46875,9.5 C12.46875,10.33125 12.409375,11.103125 12.35,11.875 L6.65,11.875 C6.590625,11.103125 6.53125,10.33125 6.53125,9.5 C6.53125,8.66875 6.590625,7.896875 6.65,7.125 L12.35,7.125 C12.409375,7.896875 12.46875,8.66875 12.46875,9.5 Z M9.5,17.8125 C8.490625,17.8125 7.3625,15.971875 6.828125,13.0625 L12.171875,13.0625 C11.6375,15.971875 10.509375,17.8125 9.5,17.8125 Z M6.828125,5.9375 C7.3625,3.028125 8.490625,1.1875 9.5,1.1875 C10.509375,1.1875 11.6375,3.028125 12.171875,5.9375 L6.828125,5.9375 Z M7.184375,1.54375 C6.471875,2.553125 5.9375,4.096875 5.640625,5.9375 L2.01875,5.9375 C3.028125,3.8 4.86875,2.196875 7.184375,1.54375 Z M1.54375,7.125 L5.4625,7.125 C5.403125,7.896875 5.34375,8.66875 5.34375,9.5 C5.34375,10.33125 5.403125,11.103125 5.4625,11.875 L1.54375,11.875 C1.30625,11.103125 1.1875,10.33125 1.1875,9.5 C1.1875,8.66875 1.30625,7.896875 1.54375,7.125 Z M2.01875,13.0625 L5.640625,13.0625 C5.9375,14.903125 6.471875,16.446875 7.184375,17.45625 C4.86875,16.803125 3.028125,15.2 2.01875,13.0625 Z M11.815625,17.45625 C12.528125,16.3875 13.0625,14.903125 13.359375,13.0625 L16.98125,13.0625 C15.971875,15.2 14.13125,16.803125 11.815625,17.45625 Z M17.45625,11.875 L13.5375,11.875 C13.596875,11.103125 13.65625,10.33125 13.65625,9.5 C13.65625,8.66875 13.596875,7.896875 13.5375,7.125 L17.45625,7.125 C17.69375,7.896875 17.8125,8.66875 17.8125,9.5 C17.8125,10.33125 17.69375,11.103125 17.45625,11.875 Z"
										id="Shape"
									></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
			<img src="../img/menu.svg" alt="menu" class="burger" id="burgerHome" />
		</header>

		<section class="hero">
			<div class="container hero__wrap">
				<div class="hero__top">
					<div class="hero__col">
						<img desc-src="../img/promo/devices.webp" class="hero-img retina" alt="apple-devices">
					</div>
					<div class="hero__col">
						<h1>Скачай Dutiap и <br> выиграй <img src="../img/promo/line-1.svg" class="line" alt="line"/></h1>
						<p>iPhone 14, MacBook Air, iPad Air, Apple Watch, AirPods Pro и еще много фирменных призов!</p>
						<span>01.04.2023 - 01.05.2023</span>
						<div class="btn-wrap">
							<a href="#" class="btn">О приложении</a>
							<a href="#" class="btn">Смотреть видео</a>
						</div>
					</div>
				</div>
				<div class="hero-bottom">
					<div class="app-links">
						<a href="android" target="_blank" class="app">
							<img src="../img/promo/Google_Play_logo.svg" alt="icon" />
							<div>
								Download <span><b>Google</b>Play</span>
							</div>
						</a>
						<a href="ios" target="_blank" class="app">
							<img src="../img/promo/App_Store_logo.svg" alt="icon" />
							<div>
								Download <span><b>App</b>Store</span>
							</div>
						</a>
					</div>
				</div>
				<img class="bubble bubble-1" src="../img/promo/bubble.png" alt="bubble">
				<img class="bubble bubble-2" src="../img/promo/bubble2.png" alt="bubble">
				<img class="bubble bubble-2--second" src="../img/promo/bubble2.png" alt="bubble">
				<img class="bubble bubble-3" src="../img/promo/bubble3.png" alt="bubble">
				<img class="bubble bubble-4" src="../img/promo/bubble4.png" alt="bubble">
				<img class="bubble bubble3" src="../img/promo/bubble3.png" alt="bubble">
			</div>
		</section>

		<section class="section">
			<div class="container section-wrap section-wrap--primary">
				<div class="section-col section-col--primary section-col--end">
					<h2 class="h2--right">iPhone 14 <img src="../img/promo/line-2.svg" class="line-2" alt="line"/></h2>
					<p class="p--right p-ch">Будет разыгран случайно среди всех активных пользователей Dutiap на нашем стриме 01.05.2023</p>
					<a href="#" class="btn">Скачать приложение</a>
				</div>
				<div class="section-col section-col--secondary">
					<img desc-src="./img/iphone.webp" class="section-img retina" alt="iphone">
					<img class="circle circle-1" src="../img/promo/circle-1.svg" alt="">
					<img class="circle circle-2" src="../img/promo/circle-2.svg" alt="">
				</div>
				<img class="bg-title" src="../img/promo/iphone-title.svg" alt="">
				<img class="bubble bubble-5" src="../img/promo/bubble-5.png" alt="bubble">
				<img class="bubble bubble-6" src="../img/promo/bubble-6.png" alt="bubble">
			</div>
		</section>

		<section class="section">
			<div class="container section-wrap section-wrap--secondary">
				<div class="section-col section-col--top">
					<img desc-src="./img/macbook.webp" class="section-img retina" alt="macbook">
				</div>
				<div class="section-col section-col--primary">
					<h2 class="h2">MacBook Air <img src="../img/promo/line-2.svg" class="line-3" alt="line"/></h2>
					<p class="p">Получит пользователь пригласивший в Dutiap больше всех друзей! А призовая 10-ка получит наушники AirPods Pro и наш фирменный мерч!</p>
					<a href="#" class="btn">Смотреть чарт пригласивших</a>
				</div>
				<img class="bg-title--right" src="../img/promo/mac-title.svg" alt="">
				<img class="bubble bubble-7" src="../img/promo/bubble-6.png" alt="bubble">
				<img class="bubble bubble-8" src="../img/promo/bubble3.png" alt="bubble">
			</div>
		</section>

		<section class="section">
			<div class="container section-wrap section-wrap--primary">
				<div class="section-col section-col--primary section-col--end">
					<h2 class="h2--right">Apple Watch <img src="../img/promo/line-1.svg" class="line-4" alt="line"/></h2>
					<p class="p--right">Получит пользователь снявший самое креативное видео о приложении Dutiap! Используй нашу музыку и отмечай хештегом #Dutiap , чтобы мы могли найти его!</p>
					<a href="#" class="btn">Смотреть примеры видео</a>
				</div>
				<div class="section-col section-col--secondary section-col--top">
					<img desc-src="./img/watch.webp" class="section-img retina" alt="watch">
					<img class="fire" src="../img/promo/fire.svg" alt="">
					<img class="fire-2" src="../img/promo/fire-2.svg" alt="">
				</div>
				<img class="bg-title" src="../img/promo/watch-title.svg" alt="">
				<img class="bubble bubble-9" src="../img/promo/bubble2.png" alt="bubble">
				<img class="bubble bubble-21" src="../img/promo/bubble2.png" alt="bubble">
			</div>
		</section>

		<section class="section">
			<div class="container section-wrap section-wrap--secondary">
				<div class="section-col">
					<img desc-src="./img/ipad.webp" class="section-img retina" alt="ipad">
				</div>
				<div class="section-col section-col--primary">
					<h2>iPad Air <img src="../img/promo/line-2.svg" class="line-5" alt="line"/></h2>
					<p class="p">Получит пользователь снявший самое популярное видео о приложении Dutiap! 
						Опубликуй своё видео в любой соц. сети и отметь хештегом #Dutiap , чтобы мы могли найти его!</p>
					<a href="#" class="btn">Смотреть примеры видео</a>
				</div>
				<img class="bg-title--right bg-small" src="../img/promo/ipad-title.svg" alt="">
				<img class="bubble bubble-10" src="../img/promo/bubble-7.png" alt="bubble">
				<img class="bubble bubble-11" src="../img/promo/bubble3.png" alt="bubble">
				<img class="bubble bubble-12" src="../img/promo/bubble-8.png" alt="bubble">
			</div>
		</section>

		<section class="section">
			<div class="container section-wrap section-wrap--primary">
				<div class="section-col section-col--primary section-col--end">
					<h2 class="h2--right">Новая регистрация = новое дерево <img src="../img/promo/line-1.svg" class="line-6" alt="line"/></h2>
					<p class="p--right">При регистрации каждого нового пользователя, мы сажаем одно новое дерево . Помогите нам засеять леса - расскажите о нашем приложении своим друзьям!</p>
				</div>
				<div class="section-col section-col--secondary">
					<img desc-src="./img/phone.webp" class="section-img retina" alt="phone">
					<img class="leaf1" src="../img/promo/leaf-1.png" alt="">
					<img class="leaf2" src="../img/promo/leaf-2.png" alt="">
					<img class="leaf3" src="../img/promo/leaf-3.png" alt="">
				</div>
				<img class="bg-title bg-title--tree" src="../img/promo/tree-title.svg" alt="">
				<img class="bubble bubble-13" src="../img/promo/bubble.png" alt="bubble">
				<img class="bubble bubble-14" src="../img/promo/bubble-9.png" alt="bubble">
				<img class="bubble bubble-15" src="../img/promo/bubble3.png" alt="bubble">
			</div>
		</section>

		<section class="section">
			<div class="container faq-wrap">
				<h2 class="h2--center">FAQ</h2>
				<div class="accordion">
					<div class="item">
						<p class="title">Кто может участвовать?</p>
						<div class="box">
							<p class="text">Идейные соображения высшего порядка, а также реализация намеченных плановых заданий требуют определения и уточнения модели развития. Идейные соображения высшего порядка, а также сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким образом рамки и место обучения кадров позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянное информационно-пропагандистское обеспечение нашей деятельности играет важную роль в формировании дальнейших направлений развития.</p>
						</div>
					</div>
					<div class="item">
						<p class="title">Где и когда будут подводиться результаты конкурса?</p>
						<div class="box">
							<p class="text">Идейные соображения высшего порядка, а также реализация намеченных плановых заданий требуют определения и уточнения модели развития. Идейные соображения высшего порядка, а также сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким образом рамки и место обучения кадров позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянное информационно-пропагандистское обеспечение нашей деятельности играет важную роль в формировании дальнейших направлений развития.</p>
						</div>
					</div>
					<div class="item">
						<p class="title">Могу ли я участвовать в нескольких розыгрышах?</p>
						<div class="box">
							<p class="text">Идейные соображения высшего порядка, а также реализация намеченных плановых заданий требуют определения и уточнения модели развития. Идейные соображения высшего порядка, а также сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким образом рамки и место обучения кадров позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянное информационно-пропагандистское обеспечение нашей деятельности играет важную роль в формировании дальнейших направлений развития.</p>
						</div>
					</div>
					<div class="item">
						<p class="title">Могу ли я участвовать в нескольких розыгрышах?</p>
						<div class="box">
							<p class="text">Идейные соображения высшего порядка, а также реализация намеченных плановых заданий требуют определения и уточнения модели развития. Идейные соображения высшего порядка, а также сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким образом рамки и место обучения кадров позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянное информационно-пропагандистское обеспечение нашей деятельности играет важную роль в формировании дальнейших направлений развития.</p>
						</div>
					</div>
					<div class="item">
						<p class="title">Могу ли я участвовать в нескольких розыгрышах?</p>
						<div class="box">
							<p class="text">Идейные соображения высшего порядка, а также реализация намеченных плановых заданий требуют определения и уточнения модели развития. Идейные соображения высшего порядка, а также сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким образом рамки и место обучения кадров позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянное информационно-пропагандистское обеспечение нашей деятельности играет важную роль в формировании дальнейших направлений развития.</p>
						</div>
					</div>
					<div class="item">
						<p class="title">Могу ли я участвовать в нескольких розыгрышах?</p>
						<div class="box">
							<p class="text">Идейные соображения высшего порядка, а также реализация намеченных плановых заданий требуют определения и уточнения модели развития. Идейные соображения высшего порядка, а также сложившаяся структура организации обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом рамки и место обучения кадров требуют определения и уточнения системы обучения кадров, соответствует насущным потребностям. Разнообразный и богатый опыт дальнейшее развитие различных форм деятельности требуют от нас анализа системы обучения кадров, соответствует насущным потребностям. Таким образом рамки и место обучения кадров позволяет выполнять важные задания по разработке новых предложений. Повседневная практика показывает, что постоянное информационно-пропагандистское обеспечение нашей деятельности играет важную роль в формировании дальнейших направлений развития.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="apps" id="apps">
			<div class="apps-title">Try it now for free and no ads!</div>
			<div class="app-links">
				<a href="https://play.google.com/store/apps/details?id=com.dutiapinc.dutiap" target="_blank" class="app">
					<img src="../img/Google_Play_logo.svg" alt="icon" />
					<div>
						Download <span><b>Google</b> Play</span>
					</div>
				</a>
				<a href="https://apps.apple.com/us/app/dutiap/id1562504099" target="_blank" class="app">
					<img src="../img/App_Store_logo.svg" alt="icon" />
					<div>
						Download <span><b>App</b> Store</span>
					</div>
				</a>
        <a href="https://pwa.dutiap.com" target="_blank" class="app">
					<img style="width: 30px; height: 30px;" src="../img/pwa_icon.svg" alt="icon" />
					<div>
						Open <span><b>WEB</b> PWA</span>
					</div>
				</a>
			</div>
			<img src="../img/circle-1.svg" alt="bubble" class="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-2" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-3" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-4" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-5" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-6" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-7" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-8" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-9" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
		</section>

		<footer>
			<p @click="goToLink('about')" style="cursor:pointer">About Us</p>
			<p @click="goToLink('support')" style="cursor:pointer">Support</p>
			<p @click="goToLink('terms')" style="cursor:pointer">Terms of Use</p>
			<p @click="goToLink('privacy')" style="cursor:pointer">Privacy Policy</p>
			<div class="lang">
				<select @change="goLang($event)">
					<option datalang="EN" selected value="EN">English</option>
					<option datalang="RU" value="RU">Русский</option>
				</select>
				<svg
					width="28px"
					height="28px"
					viewBox="0 0 19 20"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Laguage-selector-Tests"
						stroke="none"
						stroke-width="1"
						fill="none"
						fill-rule="evenodd"
					>
						<g
							id="Contact-sales_update-mock"
							transform="translate(-1082.000000, -552.000000)"
						>
							<g
								id="Nav-Copy-3"
								transform="translate(60.000000, 534.000000)"
							>
								<polygon
									id="Nav-bg"
									fill="none"
									points="0 0 1440 0 1440 56 0 56"
								></polygon>
								<g
									id="globe"
									transform="translate(1022.000000, 18.500000)"
									fill="#F2F7FF"
									fill-rule="nonzero"
								>
									<path
										d="M9.5,0 C4.275,0 0,4.275 0,9.5 C0,14.725 4.275,19 9.5,19 C14.725,19 19,14.725 19,9.5 C19,4.275 14.725,0 9.5,0 Z M16.98125,5.9375 L13.359375,5.9375 C13.0625,4.096875 12.528125,2.553125 11.815625,1.54375 C14.13125,2.196875 15.971875,3.8 16.98125,5.9375 Z M12.46875,9.5 C12.46875,10.33125 12.409375,11.103125 12.35,11.875 L6.65,11.875 C6.590625,11.103125 6.53125,10.33125 6.53125,9.5 C6.53125,8.66875 6.590625,7.896875 6.65,7.125 L12.35,7.125 C12.409375,7.896875 12.46875,8.66875 12.46875,9.5 Z M9.5,17.8125 C8.490625,17.8125 7.3625,15.971875 6.828125,13.0625 L12.171875,13.0625 C11.6375,15.971875 10.509375,17.8125 9.5,17.8125 Z M6.828125,5.9375 C7.3625,3.028125 8.490625,1.1875 9.5,1.1875 C10.509375,1.1875 11.6375,3.028125 12.171875,5.9375 L6.828125,5.9375 Z M7.184375,1.54375 C6.471875,2.553125 5.9375,4.096875 5.640625,5.9375 L2.01875,5.9375 C3.028125,3.8 4.86875,2.196875 7.184375,1.54375 Z M1.54375,7.125 L5.4625,7.125 C5.403125,7.896875 5.34375,8.66875 5.34375,9.5 C5.34375,10.33125 5.403125,11.103125 5.4625,11.875 L1.54375,11.875 C1.30625,11.103125 1.1875,10.33125 1.1875,9.5 C1.1875,8.66875 1.30625,7.896875 1.54375,7.125 Z M2.01875,13.0625 L5.640625,13.0625 C5.9375,14.903125 6.471875,16.446875 7.184375,17.45625 C4.86875,16.803125 3.028125,15.2 2.01875,13.0625 Z M11.815625,17.45625 C12.528125,16.3875 13.0625,14.903125 13.359375,13.0625 L16.98125,13.0625 C15.971875,15.2 14.13125,16.803125 11.815625,17.45625 Z M17.45625,11.875 L13.5375,11.875 C13.596875,11.103125 13.65625,10.33125 13.65625,9.5 C13.65625,8.66875 13.596875,7.896875 13.5375,7.125 L17.45625,7.125 C17.69375,7.896875 17.8125,8.66875 17.8125,9.5 C17.8125,10.33125 17.69375,11.103125 17.45625,11.875 Z"
										id="Shape"
									></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</footer>
    <div v-if="isPopup" class="popup-wrapper">
			<div @click="isPopup=false" class="close-popup"></div>
			<div class="vimeo">
				<div style="padding: 56.25% 0 0 0; position: relative">
					<iframe
						src="https://player.vimeo.com/video/564338597"
						data-src="https://player.vimeo.com/video/564338597"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						style="
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						"
						title="Dutiap Intro"
					></iframe>
				</div>
			</div>
		</div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'

function DetectDevice() {
  let isMobile = window.matchMedia || window.msMatchMedia;
  if(isMobile) {
    let match_mobile = isMobile("(pointer:coarse)");
    return match_mobile.matches;
  }
  return false;
}

export default defineComponent({
  name: 'PromoPage',
  setup() {
    console.log('Test Promo')
    const router = useRouter()
    const scroll = ref(0)
    const classBurger = ref('')
    const isPopup = ref(false)
    const isMobile = ref(DetectDevice())
    const src1 = ref()
    const src2 = ref()
    const src3 = ref()
    const src4 = ref()
    const src5 = ref()
    const src6 = ref()
    const src7 = ref()
    const src8 = ref()
    const src9 = ref()
    const windowMatchMedia = () => {
      if (window.matchMedia("(min-width: 1025px)").matches) {
        if (window.devicePixelRatio > 1) {
          src1.value = new URL('../img/rate@2x.png', import.meta.url)
          src2.value = new URL('../img/photo-0@2x.png', import.meta.url)
          src3.value = new URL('../img/photo-1@2x.png', import.meta.url)
          src4.value = new URL('../img/photo-2@2x.png', import.meta.url)
          src5.value = new URL('../img/recomm-1@2x.png', import.meta.url)
          src6.value = new URL('../img/recomm-2@2x.png', import.meta.url)
          src7.value = new URL('../img/matches@2x.png', import.meta.url)
          src8.value = new URL('../img/mode@2x.png', import.meta.url)
          src9.value = new URL('../img/trees-2@2x.png', import.meta.url)
        } else {
          src1.value = new URL('../img/rate.png', import.meta.url)
          src2.value = new URL('../img/photo-0.png', import.meta.url)
          src3.value = new URL('../img/photo-1.png', import.meta.url)
          src4.value = new URL('../img/photo-2.png', import.meta.url)
          src5.value = new URL('../img/recomm-1.png', import.meta.url)
          src6.value = new URL('../img/recomm-2.png', import.meta.url)
          src7.value = new URL('../img/matches.png', import.meta.url)
          src8.value = new URL('../img/mode.png', import.meta.url)
          src9.value = new URL('../img/trees-2.png', import.meta.url)
        }
      }
      if (window.matchMedia("(max-width: 1025px) and (min-width: 550px)").matches) {
        if (window.devicePixelRatio > 1) {
          src1.value = new URL('../img/l_rate@2x.png', import.meta.url)
          src2.value = new URL('../img/l_photo-0@2x.png', import.meta.url)
          src3.value = new URL('../img/l_photo-1@2x.png', import.meta.url)
          src4.value = new URL('../img/l_photo-2@2x.png', import.meta.url)
          src5.value = new URL('../img/l_recomm-1@2x.png', import.meta.url)
          src6.value = new URL('../img/l_recomm-2@2x.png', import.meta.url)
          src7.value = new URL('../img/l_matches@2x.png', import.meta.url)
          src8.value = new URL('../img/l_mode@2x.png', import.meta.url)
          src9.value = new URL('../img/l_trees-2@2x.png', import.meta.url)
        } else {
          src1.value = new URL('../img/l_rate.png', import.meta.url)
          src2.value = new URL('../img/l_photo-0.png', import.meta.url)
          src3.value = new URL('../img/l_photo-1.png', import.meta.url)
          src4.value = new URL('../img/l_photo-2.png', import.meta.url)
          src5.value = new URL('../img/l_recomm-1.png', import.meta.url)
          src6.value = new URL('../img/l_recomm-2.png', import.meta.url)
          src7.value = new URL('../img/l_matches.png', import.meta.url)
          src8.value = new URL('../img/l_mode.png', import.meta.url)
          src9.value = new URL('../img/l_trees-2.png', import.meta.url)
        }
      }
      if (window.matchMedia("(max-width: 550px)").matches) {
        if (window.devicePixelRatio > 1) {
          src1.value = new URL('../img/m_rate@2x.png', import.meta.url)
          src2.value = new URL('../img/m_photo-0@2x.png', import.meta.url)
          src3.value = new URL('../img/m_photo-1@2x.png', import.meta.url)
          src4.value = new URL('../img/m_photo-2@2x.png', import.meta.url)
          src5.value = new URL('../img/m_recomm-1@2x.png', import.meta.url)
          src6.value = new URL('../img/m_recomm-2@2x.png', import.meta.url)
          src7.value = new URL('../img/m_matches@2x.png', import.meta.url)
          src8.value = new URL('../img/m_mode@2x.png', import.meta.url)
          src9.value = new URL('../img/m_trees-2@2x.png', import.meta.url)
        } else {
          src1.value = new URL('../img/m_rate.png', import.meta.url)
          src2.value = new URL('../img/m_photo-0.png', import.meta.url)
          src3.value = new URL('../img/m_photo-1.png', import.meta.url)
          src4.value = new URL('../img/m_photo-2.png', import.meta.url)
          src5.value = new URL('../img/m_recomm-1.png', import.meta.url)
          src6.value = new URL('../img/m_recomm-2.png', import.meta.url)
          src7.value = new URL('../img/m_matches.png', import.meta.url)
          src8.value = new URL('../img/m_mode.png', import.meta.url)
          src9.value = new URL('../img/m_trees-2.png', import.meta.url)
        }
      }
    }
    windowMatchMedia()
    const goToLink = (link) => {
      classBurger.value = ''
      router.push({ path: link, replace: true })
    }
    const goScroll = (e) => {
      scroll.value = e.detail.scrollTop
    }
    const goToApps = () => {
      const mainContent = document.querySelector('ion-content')
      mainContent.scrollToBottom(1500)
    }
    const goLang = (ev) => {
      const lang = ev.target.value
      localStorage.setItem('langUser', lang)
      if (lang === 'RU') {
        router.replace('/ru/')
      }
    }
    const isVideo = ref(false)
    let burger;
    let closeMenu;
    onMounted(() => {
      if (window.innerWidth > 900)
      {
          isVideo.value = true
      }
      let select = document.getElementById("langSel");
      select.selectedIndex = 0;
      burger = document.querySelector('#burgerHome')
      closeMenu = document.querySelector('#closeHome')
      burger.addEventListener('click', () => {
        classBurger.value = 'active'
      })
      closeMenu.addEventListener('click', () => {
        classBurger.value = ''
      })
      window.addEventListener('resize', function() {
        windowMatchMedia()
      }, true);
    })
	return {
		goScroll, scroll, classBurger, goToApps, isPopup, src1, src2, src3, src4, src5, src6, src7, src8, src9, goToLink, goLang, isVideo, isMobile
	}
  },
  components: {
    IonContent,
    IonPage
  }
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;700&display=swap');

:root {
	--clr-light: #F2F7FF;
	--clr-dark: #131a21;
	--gap-standart: 3.2rem;
}

*, :after, :before {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

::-webkit-input-placeholder {
	color: #fff;
	opacity: 1;
}

::-moz-placeholder {
	color: #fff;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #fff;
	opacity: 1;
}

::-ms-input-placeholder {
	color: #fff;
	opacity: 1;
}

::placeholder {
	color: #fff;
	opacity: 1;
}

::-webkit-input-placeholder {
	color: #fff;
	opacity: 1;
}

html {
	height: 100%;
	font-size: 62.5%;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	font-family: "Noto Sans", sans-serif;
	color: var(--clr-light);
	background-color: var(--clr-dark);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
}

a, button, img {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-decoration: none;
	outline: 0;
}

button, input {
	background-color: transparent;
	border: 0;
	font-family: inherit;
}

button {
	cursor: pointer;
}

.container {
	max-width: 1280px;
	padding: 0 1.5rem;
	margin: 0 auto;
	height: 100%;
}

.container-md {
	max-width: 980px;
}

.container-sm {
	max-width: 800px;
}

h1 {
	font-size: clamp(3rem, calc(3rem + ((1vw - 0.32rem) * 1.6667)), 4.6rem);
	line-height: 130%;
	text-align: center;
	position: relative;
}

h2 {
	font-size: clamp(3rem, calc(3rem + ((1vw - 0.32rem) * 1.6667)), 4.6rem);
	line-height: 130%;
	position: relative;
}

.h2--right {
	text-align: right;
}

.h2--center {
	text-align: center;
}

p {
	font-size: 1.8rem;
	line-height: 150%;
}

.p--right {
	text-align: right;
}

.p-ch {
	max-width: 40ch;
}

a {
	text-decoration: none;
	color: #5798F6;
}

img, picture {
	display: block;
}

img {
	max-width: 120%;
}

/* picture {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
} */

/* Header -Start */
header {
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 1;
}

.header-links a {
	margin-right: 5rem;
}

.send-text {
	position: fixed;
	left: -150vw;
	z-index: -9;
	opacity: 0;
	width: 0;
	height: 0;
}

.header-links {
	display: flex;
	align-items: center;
	transition: .4s;
}

.header-links a {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.9rem;
	color: #f2f7ff;
	border-bottom: .1rem solid transparent;
	transition: .4s;
}

.header-links a:hover {
	color: #5798f6;
}

.logo {
	width: 20rem;
	display: block;
}

.logo img {
	height: 8rem;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.lang #globe {
	transition: .4s
}

.lang:hover #globe {
	fill: #5798f7;
}

.lang select {
	padding-left: 1rem;
	position: absolute;
	opacity: 0;
	left: 0;
	bottom: .3rem;
	font-size: 1.6rem;
	font-weight: 300;
	z-index: 8;
	cursor: pointer;
	font-family: Noto Sans;
	width: 3rem;
	height: 3rem;
}

.lang {
	position: absolute;
	cursor: pointer;
	z-index: 9;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.burger {
	position: absolute;
	top: 4.8rem;
	right: 0;
	cursor: pointer;
	display: none;
}

.close-menu {
	display: none;
	position: absolute;
	top: 3.3rem;
	left: 2rem;
	font-size: 4.5rem;
	color: #fff;
	cursor: pointer;
}

@media (max-width:500px) {
	.logo {
		width: 15rem;
		display: block;
	}

	.logo img {
		height: 6rem;
		width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.lang {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		top: 1.8rem;
		right: 1.8rem;
	}

	.logo {
		position: absolute;
		top: 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		z-index: 20;
	}

	.burger, .close-menu {
		display: block;
		top: 2rem;
	}

	.close-menu {
		top: .5rem;
	}

	.burger {
		right: auto;
		left: 2rem;
	}

	header {
		width: 100%;
	}

	.header-links {
		position: absolute;
		width: 100%;
		height: 17.6rem;
		background: var(--clr-dark);
		left: 0;
		flex-direction: column;
		top: -50rem;
		align-items: center;
		justify-content: flex-end;
		z-index: 15;
		padding-bottom: 1.5rem;
	}

	.header-links.active {
		top: 0;
	}

	.header-links a {
		margin-right: 0;
		margin-bottom: 1.6rem;
		z-index: 25;
		font-size: 1.6rem;
	}
}
/* Header - End */


/* Hero section – Start */
.hero {
	padding-top: 2rem;
	min-height: 60rem;
	position: relative;
}

.hero__wrap {
	display: grid;
	grid-template-rows: max-content 1fr;
	height: 100%;
}

.hero__top {
	display: grid;
	grid-template-columns: 1.2fr 1fr;
	height: 100%;
}

.hero__col:nth-child(1) {
	height: 100%;
	display: flex;
	align-items: center;
}

.hero-img {
	width: 100%;
}

.hero__col:nth-child(2) {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: var(--gap-standart);
}

.line {
	position: absolute;
	right: 0rem;
	bottom: -2rem;
}

.hero__col p, span {
	font-size: clamp(1.6rem, calc(1.6rem + ((1vw - 0.32rem) * 0.8333)), 2.4rem);
	text-align: center;
}

.hero__col p {
	max-width: 45ch;
}

.btn-wrap {
	display: flex;
	gap: 2.4rem;
}

.btn {
	border: .1rem solid var(--clr-light);
	border-radius: .8rem;
	padding: .8rem 3.5rem;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.9rem;
	color: var(--clr-light);
	text-align: center;
	display: block;
	position: relative;
	z-index: 999;
	width: fit-content;
}

.btn {
	transition: .4s;
	position: relative
}

.btn:hover {
	color: #000
}

.btn:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	z-index: -1;
	background: #fff;
	width: 0;
	border-radius: .7rem;
	transition: .3s
}

.btn:hover:before {
	width: 100%
}

.hero-bottom {
	display: flex;
	justify-content: center;
	padding-bottom: 10rem;
}

.app-links {
	display: flex;
	align-items: center;
	justify-content: center;
}

.app-links .app:first-child img {
	margin-left: .6rem;
}

.app-links .app:first-child {
	margin-right: 2.5rem;
}

.app {
	background: rgba(242, 247, 255, .1);
	border: .1rem solid #f2f7ff;
	border-radius: .8rem;
	transition: .4s;
	width: 17rem;
	padding: .8rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.app div {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	color: #f2f7ff;
	-moz-text-align-last: left;
	text-align-last: left;
	margin-left: 1.3rem
}

.app div span {
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 2.2rem;
	color: #f2f7ff;
	display: block
}

.app:hover {
	background: white;
	border: .1rem solid #333;
}

.app:hover * {
	color: #000;
}

.bubble-1 {
	position: absolute;
	width: 9rem;
	right: 15rem;
	bottom: 8rem;
}

.bubble-2 {
	position: absolute;
	left: 8rem;
	top: 17rem;
}

.bubble-2--second {
	position: absolute;
	top: 10rem;
	right: 10rem;
}

.bubble-3 {
	position: absolute;
	left: 30rem;
	bottom: 0;
}

.bubble3 {
	position: absolute;
	right: 5%;
	bottom: 14%;
	display: none;
}

.bubble-4 {
	position: absolute;
	top: 20rem;
	right: 7rem;
}

@media (max-width:1024px) {
	.hero {
		height: auto;
	}

	.hero__top {
		grid-template-columns: 1fr;
	}

	.hero__col:nth-child(1) {
		order: 2;
		margin-top: -10rem;
	}

	.hero__wrap {
		padding-top: 20rem;
	}

	.hero-bottom {
		padding-bottom: 4rem;
	}

	.app-links {
		margin-top: -35rem;
	}

	.bubble-1 {
		display: none;
	}

	.bubble-4 {
		display: none;
	}

	.bubble-2 {
		display: none;
	}

	.bubble-2--second {
		width: 5rem;
		left: 7%;
		top: 7%;
	}

	.bubble-3 {
		display: none;
	}

	.bubble3 {
		display: block;
	}
}

@media (max-width:550px) {
	.section-wrap p {
		max-width: 90%;
	}

	.hero__wrap {
		padding-top: 12rem;
	}

	.hero__col:nth-child(1) {
		order: 2;
		margin-top: 0rem;
		justify-content: center;
	}

	.app-links {
		flex-direction: column;
		margin-top: -5rem;
	}

	.app-links a {
		margin-bottom: 2.5rem;
		margin-right: 0 !important
	}

	.btn-wrap {
		flex-direction: column;
		gap: 1.5rem;
	}

	.btn-wrap a {
		width: 100%;
	}

	.p--right, p {
		text-align: center;
	}

	.line {
		width: 10rem;
		bottom: -1rem;
	}

	.hero-img {
		width: 115%;
		min-height: 300px;
	}
}
/* Hero section – End */

/* All Section - Start */
.section {
	position: relative;
}

.section-wrap {
	padding: clamp(2rem, calc(2rem + ((1vw - 0.32rem) * 4.1667)), 6rem) 0;
	height: 100%;
	display: grid;
}

.section-wrap--primary {
	grid-template-columns: 1fr 1.2fr;
}

.section-wrap--secondary {
	grid-template-columns: 1.2fr 1fr;
}

.section-col {
	display: flex;
	flex-direction: column;
	gap: var(--gap-standart);
}

.section-col--top {
	margin-top: -5rem;
}

.section-col--primary {
	padding: clamp(5rem, calc(5rem + ((1vw - 0.32rem) * 10.4167)), 15rem) 0;
}

.section-col--secondary {
	position: relative;
}

.section-col--end {
	align-items: end;
}

.section-img {
	width: 100%;
}

.bg-title {
	position: absolute;
	top: 12rem;
	left: -2rem;
}

.bg-title--right {
	position: absolute;
	top: 12rem;
	right: -12%;
}

.bg-small {
	right: -1%;
}

@media (max-width:550px) {
	.section-wrap--primary {
		grid-template-columns: 1fr;
	}

	.section-wrap--secondary {
		grid-template-columns: 1fr;
	}

	.section-col--primary {
		order: -1;
	}

	.section-col {
		align-items: center;
	}

	.bg-title {
		width: 95%;
		left: 3%;
		top: 0rem;
	}

	.bg-title--right {
		width: 130%;
		left: -10%;
		top: 0rem;
	}

	.bg-small {
		width: 65%;
		left: 17%;
	}
}
/* All Section - End */

/* iPhone Section – Start */
.line-2 {
	position: absolute;
	left: 11rem;
	bottom: -2rem;
}

.circle-1 {
	position: absolute;
	top: 25rem;
	left: 5rem;
}

.circle-2 {
	position: absolute;
	top: 35rem;
	right: 5rem;
}

.bubble-5 {
	position: absolute;
	left: 20rem;
	bottom: 10rem;
}

.bubble-6 {
	position: absolute;
	right: 18rem;
	bottom: 0;
}

@media (max-width:550px) {
	.bubble-5 {
		width: 4rem;
		left: 7rem;
		bottom: 10rem;
	}

	.bubble-6 {
		width: 6rem;
		left: 24rem;
		bottom: 5rem;
	}

	.circle-1 {
		width: 6rem;
		top: 10rem;
		left: 3rem;
	}

	.circle-2 {
		top: 18rem;
		right: 2rem;
		width: 6rem;
	}

	.line-2 {
		width: 10rem;
		left: 10rem;
		bottom: -1rem;
	}
}
/* iPhone Section – End */

/* MacBook Section – Start */
.line-3 {
	position: absolute;
	left: 18rem;
	bottom: -2rem;
}

.bubble-7 {
	position: absolute;
	left: 10rem;
	bottom: 5rem;
}

.bubble-8 {
	position: absolute;
	right: 30rem;
	bottom: 15rem;
}

@media (max-width:550px) {
	.line-3 {
		width: 10rem;
		left: 13rem;
		bottom: -1rem;
	}

	.bubble-7 {
		width: 6rem;
		left: 4rem;
		bottom: 12rem;
	}
	
	.bubble-8 {
		width: 3rem;
		right: 8rem;
		bottom: 10rem;
	}
}
/* MacBook Section – End */

/* Watch Section – Start */
.line-4 {
	position: absolute;
	left: 13rem;
	bottom: -2rem;
}

.fire {
	position: absolute;
	bottom: 30rem;
	left: 4rem;
}

.fire-2 {
	position: absolute;
	top: -10rem;
	right: 9rem;
}

.bubble-9 {
	position: absolute;
	left: 20rem;
	bottom: 30rem;
}

.bubble-21 {
	display: none;
}

@media (max-width:550px) {
	.line-4 {
		width: 10rem;
		left: 13rem;
		bottom: -1rem;
	}

	.fire {
		width: 9rem;
		bottom: 21rem;
		left: 1rem;
	}
	
	.fire-2 {
		width: 8rem;
		top: -14rem;
		right: 2rem;
	}
	
	.bubble-9 {
		display: none;
	}

	.bubble-21 {
		display: block;
		position: absolute;
		right: 3rem;
		bottom: 15rem;
	}
}
/* Watch Section – End */

/* Ipad Section – Start */
.line-5 {
	position: absolute;
	right: 56%;
	bottom: -2rem;
}

.bubble-10 {
	position: absolute;
	right: 15rem;
	bottom: 35rem;
}

.bubble-11 {
	position: absolute;
	right: 45rem;
	bottom: 30rem;
}

.bubble-12 {
	position: absolute;
	left: 13rem;
	bottom: 13rem;
}

@media (max-width:550px) {
	.line-5 {
		width: 10rem;
		right: -40%;
		bottom: -2rem;
	}

	.bubble-10 {
		width: 7rem;
		right: 7rem;
		bottom: 17rem;
	}

	.bubble-11 {
		width: 3rem;
		left: 8rem;
		top: 27rem;
	}

	.bubble-12 {
		width: 4rem;
		left: 5rem;
		bottom: 20rem;
	}
}
/* Ipad Section – End */

/* Tree Section – Start */
.line-6 {
	position: absolute;
	left: 85%;
	bottom: -2rem;
}

.bubble-13 {
	position: absolute;
	width: 10rem;
	right: 30rem;
	bottom: 20rem;
}

.bubble-14 {
	position: absolute;
	left: 13rem;
	bottom: 29rem;
}

.bubble-15 {
	position: absolute;
	right: 15rem;
	bottom: 30rem;
}

.leaf1 {
	position: absolute;
	left: 20rem;
	top: 10rem;
}

.leaf2 {
	position: absolute;
	left: 12rem;
	top: 15rem;
}

.leaf3 {
	position: absolute;
	left: -6rem;
	top: 14rem;
}

@media (max-width:550px) {
	.leaf1 {
		width: 4rem;
		left: 10rem;
		top: -9rem;
	}
	
	.leaf2 {
		width: 3rem;
		left: 7rem;
		top: -5rem;
	}
	
	.leaf3 {
		width: 3rem;
		left: 2rem;
		top: -8rem;
	}

	.bubble-13 {
		width: 8rem;
		right: 3rem;
		bottom: 16rem;
	}
	
	.bubble-14 {
		width: 5rem;
		left: 5rem;
		top: 15rem;
	}
	
	.bubble-15 {
		width: 3rem;
		right: 2rem;
		bottom: 25rem;
	}

	.h2--right {
		text-align: center;
		max-width: 20ch;
	}

	.bg-title--tree {
		margin-top: 2rem;
		width: 105%;
		left: -5%
	}

	.line-6 {
		width: 12rem;
		left: 65%;
		bottom: -1rem;
	}
}
/* Tree Section – End */


/* FAQ Section – Start */
.faq-wrap {
	padding-top: 15rem;
	padding-bottom: 15rem;
}

.faq-wrap h2 {
	margin-bottom: 3rem;
}

.accordion .item {
	overflow: hidden;
	margin-bottom: 1rem;
	border-radius: 1rem;
}

.accordion .title {
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	text-align: start;
	position: relative;
	padding: 3rem;
	background-color: #3c445630;
	color: var(--clr-light);
	font-size: clamp(1.4rem, calc(1.4rem + ((1vw - 0.32rem) * 1.0417)), 2.4rem);
	cursor: pointer;
	font-family: 'Noto Sans', sans-serif;
	font-weight: 300;
}

.accordion .title::after {
	content: url(../img/promo/chevron-down.svg);
}

.accordion .title.active::after {
	content: url(../img/promo/chevron-up.svg);
}

.accordion .box {
	overflow: hidden;
	max-height: 0;
	transition: 0.3s max-height ease-out;
}

.accordion .text {
	text-align: start;
	padding-bottom: 3rem;
	padding-left: clamp(3rem, calc(3rem + ((1vw - 0.32rem) * 3.125)), 6rem);
	padding-right: clamp(3rem, calc(3rem + ((1vw - 0.32rem) * 3.125)), 6rem);
	background-color: #3c445630;
	font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.32rem) * 0.625)), 1.8rem);
	color: var(--clr-light);
	line-height: clamp(1.6rem, calc(1.6rem + ((1vw - 0.32rem) * 1.4583)), 3rem);
	font-weight: 200;
}

@media (max-width:550px) {
	.accordion .title {
		padding: 2rem;
	}

	.faq-wrap {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.accordion .title::after {
		content: url(../img/promo/chevron-down.svg);
		transform: scale(.7);
	}
}
/* FAQ Section – End */


/* Apps Section – Start */
	.apps {
		background: linear-gradient(111.32deg, #79AEFB 18.45%, #5798F6 84.63%);
		height: 60rem;
		position: relative;
	}

	.apps-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.apps-wrap h2 {
		margin-bottom: 5.6rem;
	}

	.app-links-2 {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2.4rem;
	}
	
	.app-links-2 .app:first-child img {
		margin-left: .6rem;
	}
	
	.app-links-2 .app:first-child {
		margin-right: 2.5rem;
	}
	
	.app-2 {
		background: #F2F7FF;
		border-radius: .8rem;
		border: white solid .1rem;
		transition: .4s;
		width: 17rem;
		padding: .8rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.app-2 div {
		font-family: Noto Sans;
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.4rem;
		color: var(--clr-dark);
		-moz-text-align-last: left;
		text-align-last: left;
		margin-left: 1.3rem
	}
	
	.app-2 div span {
		font-family: Noto Sans;
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 2.2rem;
		color: var(--clr-dark);
		display: block
	}
	
	.app-2:hover {
		background: transparent;
		border: .1rem solid #333;
	}

	.ellipse-1 {
		position: absolute;
		right: 5rem;
		top: -23rem;
	}

	.ellipse-2 {
		position: absolute;
		right: 15rem;
		top: -10rem;
	}

	.ellipse-3 {
		position: absolute;
		right: 13rem;
		bottom: 35rem;
	}

	.ellipse-4 {
		position: absolute;
		right: 70rem;
		bottom: 35rem;
	}

	.ellipse-5 {
		position: absolute;
		left: 30rem;
		bottom: 43rem;
	}

	.ellipse-6 {
		position: absolute;
		left: 14rem;
		bottom: 35rem;
	}

	.ellipse-7 {
		position: absolute;
		left: 5rem;
		bottom: 48rem;
	}

	.ellipse-8 {
		position: absolute;
		left: 14rem;
		top: -8rem;
	}

	.ellipse-9 {
		position: absolute;
		left: 32rem;
		top: -22rem;
	}

	@media (max-width:550px) {
		.app-links-2 {
			flex-direction: column;
			gap: 1.6rem;
		}

		.ellipse-1 {
			right: 5rem;
			top: -24rem;
			width: 10rem;
			opacity: 0.3;
		}
	
		.ellipse-2 {
			display: none;
		}
	
		.ellipse-3 {
			width: 8rem;
			right: 2rem;
			bottom: 35rem;
		}
	
		.ellipse-4 {
			display: none;
		}
	
		.ellipse-5 {
			display: none;
		}
	
		.ellipse-6 {
			width: 6rem;
			left: 8rem;
			bottom: 33rem;
		}
	
		.ellipse-7 {
			display: none;
		}
	
		.ellipse-8 {
			left: 2rem;
			top: 3rem;
		}
	
		.ellipse-9 {
			display: none;
		}
	}
/* Apps Section – End */


/* Info Section – Start */
.info-wrap {
	padding: 15rem 1.5rem;
}

.info-wrap p {
	text-align: start;
	font-size: 1.2rem;
	color: #F2F7FF;
	line-height: 2rem;

}

.bubble-16 {
	position: absolute;
	right: 3rem;
	top: 12rem;
}

.bubble-17 {
	position: absolute;
	right: 15rem;
	bottom: 37rem;
}

.bubble-18 {
	position: absolute;
	left: 2rem;
	top: -25rem;
}

@media (max-width:550px) {
	.bubble-18 {
		left: 2rem;
		top: 120rem;
	}
	.info-wrap {
		padding: 6rem 1.5rem;
	}

	.bubble-16, .bubble-17 {
		display: none;
	}
}
/* Info Section – End */

/* Footer - Start */
footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7.3rem 0;
}

footer a {
	margin-right: 10rem;
	font-family: Noto Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 2.5rem;
	color: #f2f7ff;
	border-bottom: .1rem solid transparent;
	transition: .4s;
}

footer a:hover {
	color: #5798f6;
}

footer a:last-child {
	margin-right: 0;
}

.footer__inner {
	padding: 7rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer__link {
	color: #f2f7ff;
	font-size: 1.8rem;
	transition: color .1s ease;
}

.footer__link:hover {
	color: #5798f6;
}

.footer .container-sm {
	max-width: 68rem;
}

@media screen and (max-width:80rem) {
	.footer__inner {
		padding-bottom: 5rem;
	}
}

@media screen and (max-width:50rem) {
	.footer__inner {
		padding-top: 5rem;
	}

	.footer__link {
		font-size: 1.4rem;
		flex-basis: 45%;
		margin-top: 2.4rem;
	}

	.footer__link:nth-child(odd) {
		text-align: right
	}
}

footer .lang {
	position: relative;
	-webkit-transform: none;
	transform: none
}

@media (max-width:100rem) {
	footer a {
		margin-right: 4rem
	}
}

@media (max-width:55rem) {
	footer {
		flex-wrap: wrap;
		padding: 5rem 0;
		position: relative;
		z-index: 5;
	}

	footer a {
		margin-right: 0;
		width: 30%;
	}

	footer a {
		border: none !important;
	}

	footer a:nth-child(1), footer a:nth-child(3) {
		text-align: right;
		margin-right: 4rem;
	}

	footer a:nth-child(1), footer a:nth-child(2) {
		margin-bottom: 3rem;
	}

	footer .lang {
		position: relative;
		-webkit-transform: none;
		transform: none;
		width: 100%;
		text-align: center;
		margin-top: 2.5rem;
		top: auto;
		right: auto;
	}

	footer .lang select {
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}

@media (max-width:50rem) {
	footer a {
		font-size: 1.4rem
	}
}
/* Footer - End */

/* Animation */
 .bubble {
	transition: 1.5s ease-out !important;
	transform: translateY(-2px);
 } 

 .ellipse {
	transition: 1.5s ease-out !important;
	transform: translateY(-2px);
 }
 
 .bubble-2 {
	transition: 1.5s ease-out !important;
	transform: translateY(-2px);
 }
 
 .circle {
	transition: 1.5s ease-out !important;
	transform: translateY(-2px);
 }
 
 .circle-2 {
	transition: 1s ease-out !important;
	transform: translateY(-2px);
 }
 
 .leaf2 {
	transition: 1s ease-out !important;
	transform: translateY(-2px);
 }
 
 .leaf1 {
	transition: 1s ease-out !important;
	transform: translateY(-2px);
 }
 .leaf3 {
	transition: 1s ease-out !important;
	transform: translateY(-2px);
 }
 .fire {
	transition: 1.5s ease-out !important;
	transform: translateY(-2px);
 }
 
 .fire-2 {
	transition: 1.5s ease-out !important;
	transform: translateY(-2px);
 }
</style>
