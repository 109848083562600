<template>
  <ion-page>
    <ion-content ref="mainContent" :fullscreen="true" :scroll-events="true" @ionScroll="goScroll($event)" style="background:#131a21;--background:#131a21;">
    <header>
			<div @click="goToLink('/')" href="" class="logo" style="cursor:pointer">
				<img src="../img/logo.svg" alt="logo" />
			</div>
			<div class="header-links" :class="classBurger">
				<p @click="goToLink('about')" style="cursor:pointer">О нас</p>
				<p @click="goToLink('support')" style="cursor:pointer">Поддержка</p>
				<span class="close-menu" id="closeRuHome">×</span>
			</div>
			<div class="lang">
				<select @input="goLang($event)" id="langSelRu">
					<option datalang="EN" value="EN">English</option>
					<option datalang="RU" selected value="RU">Русский</option>
				</select>
				<svg
					width="28px"
					height="28px"
					viewBox="0 0 19 20"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Laguage-selector-Tests"
						stroke="none"
						stroke-width="1"
						fill="none"
						fill-rule="evenodd"
					>
						<g
							id="Contact-sales_update-mock"
							transform="translate(-1082.000000, -552.000000)"
						>
							<g
								id="Nav-Copy-3"
								transform="translate(60.000000, 534.000000)"
							>
								<polygon
									id="Nav-bg"
									fill="none"
									points="0 0 1440 0 1440 56 0 56"
								></polygon>
								<g
									id="globe"
									transform="translate(1022.000000, 18.500000)"
									fill="#F2F7FF"
									fill-rule="nonzero"
								>
									<path
										d="M9.5,0 C4.275,0 0,4.275 0,9.5 C0,14.725 4.275,19 9.5,19 C14.725,19 19,14.725 19,9.5 C19,4.275 14.725,0 9.5,0 Z M16.98125,5.9375 L13.359375,5.9375 C13.0625,4.096875 12.528125,2.553125 11.815625,1.54375 C14.13125,2.196875 15.971875,3.8 16.98125,5.9375 Z M12.46875,9.5 C12.46875,10.33125 12.409375,11.103125 12.35,11.875 L6.65,11.875 C6.590625,11.103125 6.53125,10.33125 6.53125,9.5 C6.53125,8.66875 6.590625,7.896875 6.65,7.125 L12.35,7.125 C12.409375,7.896875 12.46875,8.66875 12.46875,9.5 Z M9.5,17.8125 C8.490625,17.8125 7.3625,15.971875 6.828125,13.0625 L12.171875,13.0625 C11.6375,15.971875 10.509375,17.8125 9.5,17.8125 Z M6.828125,5.9375 C7.3625,3.028125 8.490625,1.1875 9.5,1.1875 C10.509375,1.1875 11.6375,3.028125 12.171875,5.9375 L6.828125,5.9375 Z M7.184375,1.54375 C6.471875,2.553125 5.9375,4.096875 5.640625,5.9375 L2.01875,5.9375 C3.028125,3.8 4.86875,2.196875 7.184375,1.54375 Z M1.54375,7.125 L5.4625,7.125 C5.403125,7.896875 5.34375,8.66875 5.34375,9.5 C5.34375,10.33125 5.403125,11.103125 5.4625,11.875 L1.54375,11.875 C1.30625,11.103125 1.1875,10.33125 1.1875,9.5 C1.1875,8.66875 1.30625,7.896875 1.54375,7.125 Z M2.01875,13.0625 L5.640625,13.0625 C5.9375,14.903125 6.471875,16.446875 7.184375,17.45625 C4.86875,16.803125 3.028125,15.2 2.01875,13.0625 Z M11.815625,17.45625 C12.528125,16.3875 13.0625,14.903125 13.359375,13.0625 L16.98125,13.0625 C15.971875,15.2 14.13125,16.803125 11.815625,17.45625 Z M17.45625,11.875 L13.5375,11.875 C13.596875,11.103125 13.65625,10.33125 13.65625,9.5 C13.65625,8.66875 13.596875,7.896875 13.5375,7.125 L17.45625,7.125 C17.69375,7.896875 17.8125,8.66875 17.8125,9.5 C17.8125,10.33125 17.69375,11.103125 17.45625,11.875 Z"
										id="Shape"
									></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
			<img src="../img/menu.svg" alt="menu" class="burger" id="burgerRuHome" />
		</header>

		<section class="main">
			<div class="video">
				<video
          v-if="isVideo"
					id="video"
					autoplay="autoplay"
					muted="muted"
					width="auto"
					height="100%"
					loop="loop"
					preload="auto"
					poster="../img/preview.jpg"
				>
          <source src="../video/bg.webm" type="video/webm; codecs=vp9"/>
          <source src="../video/bg.av1.mp4" type="video/mp4; codecs=av01.0.05M.08,opus" />
          <source src="../video/bg.hevc.mp4" type="video/mp4; codecs=hvc1"/>
          <source src="../video/bg.h264.mp4" type="video/mp4; codecs=avc1.4D401E,mp4a.40.2"/>
        </video>
			</div>
			<h1 class="main-title">
				В Dutiap вы можете объективно оценивать свои фото и знакомиться
				с людьми на основе ваших предпочтений
			</h1>
			<div @click="isPopup=true" class="player-btn">
				<svg
					width="104"
					height="104"
					viewBox="0 0 104 104"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						cx="52"
						cy="52"
						r="50.75"
						fill="white"
						fill-opacity="0.2"
						stroke="white"
						stroke-width="2.5"
					/>
					<path
						d="M68.9 50.4989C70.0556 51.166 70.0556 52.834 68.9 53.5011L44.85 67.3864C43.6944 68.0535 42.25 67.2196 42.25 65.8853V38.1147C42.25 36.7804 43.6944 35.9465 44.85 36.6136L68.9 50.4989Z"
						fill="white"
					/>
				</svg>
				<span>Смотреть видео</span>
			</div>
			<div class="app-links">
				<a :href="'https://play.google.com/store/apps/details?id=com.dutiapinc.dutiap&hl=ru' + (utm !== '' ? '&' + utm : '')" target="_blank" class="app">
					<img src="../img/Google_Play_logo.svg" alt="icon" />
					<div>
						Download <span><b>Google</b> Play</span>
					</div>
				</a>
				<a :href="'https://apps.apple.com/ru/app/dutiap/id1562504099' + (utm !== '' ? '?' + utm : '')" target="_blank" class="app">
					<img src="../img/App_Store_logo.svg" alt="icon" />
					<div>
						Download <span><b>App</b> Store</span>
					</div>
				</a>
        <!-- <a href="https://pwa.dutiap.com" target="_blank" class="app">
					<img style="width: 30px; height: 30px;" src="../img/pwa_icon.svg" alt="icon" />
					<div>
						Open <span><b>WEB</b> PWA</span>
					</div>
				</a> -->
			</div>
		</section>

		<section class="content-block rate">
			<div class="info-block">
				<h2 class="title-block">
					Голосуй <img src="../img/line.svg" class="line" alt="line" />
				</h2>
				<div class="text-block">
					Оценивайте фото других пользователей! Количество оцененных
					профилей равно количеству оценок для ваших фото. Чем больше
					голосуешь, тем объективнее будет оценен твой профайл!
					<span>Голосуй</span>
				</div>
				<div @click="goToApps" class="btn">Скачать приложение</div>
			</div>
			<div class="img-block">
				<img :src="src1" class="retina" alt="rate" />
				<img src="../img/green_circle.svg" alt="circle" class="circle" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
				<img
					src="../img/yellow-circle.svg"
					alt="circle"
					class="circle circle-2"
          :style="'transform:translateY(' + scroll * 0.06 + 'px)'"
				/>
			</div>
			<!-- <img src="img/bubble_rate.png" alt="bubble" class="bubble"> -->

			<img src="../img/bubble_rate.svg" class="bubble" alt="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/bubble-2.svg" class="bubble bubble-2" alt="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
		</section>

		<section class="content-block photo">
			<div class="img-block">
				<!-- <img src="img/photo.png" alt="rate"> -->
				<img
					:src="src2"
					class="photo-0 retina"
					alt="photo"
				/>
				<img
					:src="src3"
					class="photo-1 retina"
					alt="photo"
          :style="'transform:rotate(' + (1 - scroll * 0.007) + 'deg)'"
				/>
				<img
					:src="src4"
					class="photo-2 retina"
					alt="photo"
          :style="'transform:rotate(' + (scroll * 0.007) + 'deg)'"
				/>
			</div>
			<div class="info-block">
				<h2 class="title-block">
					Выбери лучшее<br />фото
					<img src="../img/line.svg" class="line" alt="line" />
				</h2>
				<div class="text-block">
					В своём профиле вы можете просмотреть рейтинг каждой
					фотографии и выбрать лучшие!
					<span>Фото</span>
				</div>
				<div @click="goToApps" class="btn">Скачать приложение</div>
			</div>
			<img src="../img/bubble.svg" alt="bubble" class="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
		</section>

		<section class="content-block recomm">
			<div class="info-block">
				<h2 class="title-block">
					Рекомендации
					<img src="../img/line.svg" class="line" alt="line" />
				</h2>
				<div class="text-block">
					Наш алгоритм понимает ваши предпочтения и рекомендует 10
					новых пользователей каждый день специально для вас!
					<span>Рекоме<br />ндации</span>
				</div>
				<div @click="goToApps" class="btn">Скачать приложение</div>
			</div>
			<div class="img-block">
				<img
          v-if="!isMobile"
					:src="src5"
					class="recomm__first-img retina"
					alt="recommendation"
          :style="'transform:translate(' + (scroll * 0.023) + 'px,' + (scroll * 0.041) + 'px) scale(' + (1 - scroll / 10000) + ')'"
				/>
        <img
          v-if="isMobile"
					:src="src5"
					class="recomm__first-img retina"
					alt="recommendation"
          :style="'transform:translate(' + (scroll * 0.04) + 'px,' + (scroll * 0.04) + 'px) scale(' + (1.1 - scroll / 10000) + ')'"
				/>
				<img
					:src="src6"
					class="recomm__second-img retina"
					alt="recommendation"
				/>
			</div>
			<img src="../img/bubble-3.svg" alt="bubble" class="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/bubble-3.svg" alt="bubble" class="bubble bubble-3" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img
				src="../img/bubble_rate.svg"
				alt="bubble"
				class="bubble bubble-2"
        :style="'transform:translateY(' + scroll * 0.06 + 'px)'"
			/>
		</section>

		<section class="content-block matches">
			<div class="img-block">
				<img
					:src="src7"
					class="retina"
					alt="rate"
				/>
				<img src="../img/Chat.svg" alt="circle" class="circle" :style="'transform:translateY(' + scroll * 0.06 + 'px)'">
				<img src="../img/Chat2.svg" alt="circle" class="circle circle-2" :style="'transform:translateY(' + scroll * 0.06 + 'px)'">
			</div>
			<div class="info-block">
				<h2 class="title-block">
					Match!<img src="../img/line.svg" class="line" alt="line" />
				</h2>
				<div class="text-block">
					При взаимной симпатии или оценке выше 8, вы сразу можете
					начать общение!
					<span>Match!</span>
				</div>
				<div @click="goToApps" class="btn">Скачать приложение</div>
			</div>
			<img src="../img/bubble_rate.svg" alt="bubble" class="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
		</section>

		<section class="mode">
			<div class="title-block">Темная тема</div>
			<div class="subtitle-block">
				Во тьме ночной, при свете дня,<br />
				Оценят точно здесь тебя!
			</div>
			<div class="mode-img">
				<img :src="src8" class="retina" alt="dark" />
			</div>
			<img src="../img/bubble_rate.svg" alt="bubble" class="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img
				src="../img/bubble_rate.svg"
				alt="bubble"
				class="bubble bubble-2"
        :style="'transform:translateY(' + scroll * 0.06 + 'px)'"
			/>
			<img src="../img/bubble-3.svg" alt="bubble" class="bubble bubble-3" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img
				src="../img/bubble_rate.svg"
				alt="bubble"
				class="bubble bubble-4"
        :style="'transform:translateY(' + scroll * 0.06 + 'px)'"
			/>
		</section>

		<section class="content-block trees">
			<div class="info-block">
				<h2 class="title-block">
					Новая регистрация =<br />новое дерево
					<img src="../img/line.svg" class="line" alt="line" />
				</h2>
				<div class="text-block">
					При регистрации каждого нового пользователя, мы сажаем одно новое дерево. Помогите нам засеять леса — расскажите о нашем приложении своим друзьям!
					<span>Дерево</span>
				</div>
			</div>
			<div class="img-block">
				<img
					:src="src9"
					class="trees__main-img retina"
					alt="trees"
				/>
			</div>
			<img src="../img/leaf-3.svg" alt="leaf" class="leaf leaf-3" :style="'transform:translateY(' + scroll * 0.05 + 'px)'" />
			<img src="../img/leaf-2.svg" alt="leaf" class="leaf leaf-2" :style="'transform:translateY(' + scroll * 0.05 + 'px)'" />
			<img src="../img/leaf-1.svg" alt="leaf" class="leaf" :style="'transform:translateY(' + scroll * 0.05 + 'px)'" />
		</section>

		<section class="apps" id="apps">
			<div class="apps-title">Скачай бесплатно и без рекламы!</div>
			<div class="app-links">
				<a :href="'https://play.google.com/store/apps/details?id=com.dutiapinc.dutiap&hl=ru' + (utm !== '' ? '&' + utm : '')" target="_blank" class="app">
					<img src="../img/Google_Play_logo.svg" alt="icon" />
					<div>
						Download <span><b>Google</b> Play</span>
					</div>
				</a>
				<a :href="'https://apps.apple.com/ru/app/dutiap/id1562504099' + (utm !== '' ? '?' + utm : '')"  target="_blank" class="app">
					<img src="../img/App_Store_logo.svg" alt="icon" />
					<div>
						Download <span><b>App</b> Store</span>
					</div>
				</a>
        <!-- <a href="https://pwa.dutiap.com" target="_blank" class="app">
					<img style="width: 30px; height: 30px;" src="../img/pwa_icon.svg" alt="icon" />
					<div>
						Open <span><b>WEB</b> PWA</span>
					</div>
				</a> -->
			</div>
			<img src="../img/circle-1.svg" alt="bubble" class="bubble" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-2" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-3" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-4" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-1.svg" alt="bubble" class="bubble bubble-5" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-6" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-7" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-8" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
			<img src="../img/circle-2.svg" alt="bubble" class="bubble bubble-9" :style="'transform:translateY(' + scroll * 0.06 + 'px)'" />
		</section>

		<footer>
			<p @click="goToLink('about')" style="cursor:pointer">О нас</p>
			<p @click="goToLink('support')" style="cursor:pointer">Поддержка</p>
			<p @click="goToLink('terms')" style="cursor:pointer">Terms of Use</p>
			<p @click="goToLink('privacy')" style="cursor:pointer">Privacy Policy</p>
			<div class="lang">
				<select @change="goLang($event)">
					<option datalang="EN" value="EN">English</option>
					<option datalang="RU" selected value="RU">Русский</option>
				</select>
				<svg
					width="28px"
					height="28px"
					viewBox="0 0 19 20"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<g
						id="Laguage-selector-Tests"
						stroke="none"
						stroke-width="1"
						fill="none"
						fill-rule="evenodd"
					>
						<g
							id="Contact-sales_update-mock"
							transform="translate(-1082.000000, -552.000000)"
						>
							<g
								id="Nav-Copy-3"
								transform="translate(60.000000, 534.000000)"
							>
								<polygon
									id="Nav-bg"
									fill="none"
									points="0 0 1440 0 1440 56 0 56"
								></polygon>
								<g
									id="globe"
									transform="translate(1022.000000, 18.500000)"
									fill="#F2F7FF"
									fill-rule="nonzero"
								>
									<path
										d="M9.5,0 C4.275,0 0,4.275 0,9.5 C0,14.725 4.275,19 9.5,19 C14.725,19 19,14.725 19,9.5 C19,4.275 14.725,0 9.5,0 Z M16.98125,5.9375 L13.359375,5.9375 C13.0625,4.096875 12.528125,2.553125 11.815625,1.54375 C14.13125,2.196875 15.971875,3.8 16.98125,5.9375 Z M12.46875,9.5 C12.46875,10.33125 12.409375,11.103125 12.35,11.875 L6.65,11.875 C6.590625,11.103125 6.53125,10.33125 6.53125,9.5 C6.53125,8.66875 6.590625,7.896875 6.65,7.125 L12.35,7.125 C12.409375,7.896875 12.46875,8.66875 12.46875,9.5 Z M9.5,17.8125 C8.490625,17.8125 7.3625,15.971875 6.828125,13.0625 L12.171875,13.0625 C11.6375,15.971875 10.509375,17.8125 9.5,17.8125 Z M6.828125,5.9375 C7.3625,3.028125 8.490625,1.1875 9.5,1.1875 C10.509375,1.1875 11.6375,3.028125 12.171875,5.9375 L6.828125,5.9375 Z M7.184375,1.54375 C6.471875,2.553125 5.9375,4.096875 5.640625,5.9375 L2.01875,5.9375 C3.028125,3.8 4.86875,2.196875 7.184375,1.54375 Z M1.54375,7.125 L5.4625,7.125 C5.403125,7.896875 5.34375,8.66875 5.34375,9.5 C5.34375,10.33125 5.403125,11.103125 5.4625,11.875 L1.54375,11.875 C1.30625,11.103125 1.1875,10.33125 1.1875,9.5 C1.1875,8.66875 1.30625,7.896875 1.54375,7.125 Z M2.01875,13.0625 L5.640625,13.0625 C5.9375,14.903125 6.471875,16.446875 7.184375,17.45625 C4.86875,16.803125 3.028125,15.2 2.01875,13.0625 Z M11.815625,17.45625 C12.528125,16.3875 13.0625,14.903125 13.359375,13.0625 L16.98125,13.0625 C15.971875,15.2 14.13125,16.803125 11.815625,17.45625 Z M17.45625,11.875 L13.5375,11.875 C13.596875,11.103125 13.65625,10.33125 13.65625,9.5 C13.65625,8.66875 13.596875,7.896875 13.5375,7.125 L17.45625,7.125 C17.69375,7.896875 17.8125,8.66875 17.8125,9.5 C17.8125,10.33125 17.69375,11.103125 17.45625,11.875 Z"
										id="Shape"
									></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</footer>
    <div v-if="isPopup" class="popup-wrapper">
			<div @click="isPopup=false" class="close-popup"></div>
			<div class="vimeo">
				<div style="padding: 56.25% 0 0 0; position: relative">
					<iframe
						src="https://player.vimeo.com/video/560581570"
						data-src="https://player.vimeo.com/video/560581570"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						style="
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						"
						title="Dutiap Intro"
					></iframe>
				</div>
			</div>
		</div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'

function DetectDevice() {
  let isMobile = window.matchMedia || window.msMatchMedia;
  if(isMobile) {
    let match_mobile = isMobile("(pointer:coarse)");
    return match_mobile.matches;
  }
  return false;
}

export default defineComponent({
  name: 'RuHomePage',
  setup() {
    const router = useRouter()
    const scroll = ref(0)
    const classBurger = ref('')
    const isPopup = ref(false)
    let path = window.location.href;
    let utm = ref('main_page_ru')
    const indexPath = path.indexOf('utm_source')
    if (indexPath !== -1) {
      utm.value = path.substring(indexPath)
    }
    const isMobile = ref(DetectDevice())
    const src1 = ref()
    const src2 = ref()
    const src3 = ref()
    const src4 = ref()
    const src5 = ref()
    const src6 = ref()
    const src7 = ref()
    const src8 = ref()
    const src9 = ref()
    const windowMatchMedia = () => {
      if (window.matchMedia("(min-width: 1025px)").matches) {
        if (window.devicePixelRatio > 1) {
          src1.value = new URL('../img/rate@2x.png', import.meta.url)
          src2.value = new URL('../img/photo-0@2x.png', import.meta.url)
          src3.value = new URL('../img/photo-1@2x.png', import.meta.url)
          src4.value = new URL('../img/photo-2@2x.png', import.meta.url)
          src5.value = new URL('../img/recomm-1@2x.png', import.meta.url)
          src6.value = new URL('../img/recomm-2@2x.png', import.meta.url)
          src7.value = new URL('../img/matches@2x.png', import.meta.url)
          src8.value = new URL('../img/mode@2x.png', import.meta.url)
          src9.value = new URL('../img/trees-2@2x.png', import.meta.url)
        } else {
          src1.value = new URL('../img/rate.png', import.meta.url)
          src2.value = new URL('../img/photo-0.png', import.meta.url)
          src3.value = new URL('../img/photo-1.png', import.meta.url)
          src4.value = new URL('../img/photo-2.png', import.meta.url)
          src5.value = new URL('../img/recomm-1.png', import.meta.url)
          src6.value = new URL('../img/recomm-2.png', import.meta.url)
          src7.value = new URL('../img/matches.png', import.meta.url)
          src8.value = new URL('../img/mode.png', import.meta.url)
          src9.value = new URL('../img/trees-2.png', import.meta.url)
        }
      }
      if (window.matchMedia("(max-width: 1025px) and (min-width: 550px)").matches) {
        if (window.devicePixelRatio > 1) {
          src1.value = new URL('../img/l_rate@2x.png', import.meta.url)
          src2.value = new URL('../img/l_photo-0@2x.png', import.meta.url)
          src3.value = new URL('../img/l_photo-1@2x.png', import.meta.url)
          src4.value = new URL('../img/l_photo-2@2x.png', import.meta.url)
          src5.value = new URL('../img/l_recomm-1@2x.png', import.meta.url)
          src6.value = new URL('../img/l_recomm-2@2x.png', import.meta.url)
          src7.value = new URL('../img/l_matches@2x.png', import.meta.url)
          src8.value = new URL('../img/l_mode@2x.png', import.meta.url)
          src9.value = new URL('../img/l_trees-2@2x.png', import.meta.url)
        } else {
          src1.value = new URL('../img/l_rate.png', import.meta.url)
          src2.value = new URL('../img/l_photo-0.png', import.meta.url)
          src3.value = new URL('../img/l_photo-1.png', import.meta.url)
          src4.value = new URL('../img/l_photo-2.png', import.meta.url)
          src5.value = new URL('../img/l_recomm-1.png', import.meta.url)
          src6.value = new URL('../img/l_recomm-2.png', import.meta.url)
          src7.value = new URL('../img/l_matches.png', import.meta.url)
          src8.value = new URL('../img/l_mode.png', import.meta.url)
          src9.value = new URL('../img/l_trees-2.png', import.meta.url)
        }
      }
      if (window.matchMedia("(max-width: 550px)").matches) {
        if (window.devicePixelRatio > 1) {
          src1.value = new URL('../img/m_rate@2x.png', import.meta.url)
          src2.value = new URL('../img/m_photo-0@2x.png', import.meta.url)
          src3.value = new URL('../img/m_photo-1@2x.png', import.meta.url)
          src4.value = new URL('../img/m_photo-2@2x.png', import.meta.url)
          src5.value = new URL('../img/m_recomm-1@2x.png', import.meta.url)
          src6.value = new URL('../img/m_recomm-2@2x.png', import.meta.url)
          src7.value = new URL('../img/m_matches@2x.png', import.meta.url)
          src8.value = new URL('../img/m_mode@2x.png', import.meta.url)
          src9.value = new URL('../img/m_trees-2@2x.png', import.meta.url)
        } else {
          src1.value = new URL('../img/m_rate.png', import.meta.url)
          src2.value = new URL('../img/m_photo-0.png', import.meta.url)
          src3.value = new URL('../img/m_photo-1.png', import.meta.url)
          src4.value = new URL('../img/m_photo-2.png', import.meta.url)
          src5.value = new URL('../img/m_recomm-1.png', import.meta.url)
          src6.value = new URL('../img/m_recomm-2.png', import.meta.url)
          src7.value = new URL('../img/m_matches.png', import.meta.url)
          src8.value = new URL('../img/m_mode.png', import.meta.url)
          src9.value = new URL('../img/m_trees-2.png', import.meta.url)
        }
      }
    }
    windowMatchMedia()
    const goToLink = (link) => {
	classBurger.value = ''
      router.push({ path: link, replace: true })
    }
    const goScroll = (e) => {
      scroll.value = e.detail.scrollTop
    }
    const goToApps = () => {
      const mainContent = document.querySelector('ion-content')
      mainContent.scrollToBottom(1500)
    }
    const goLang = (ev) => {
      const lang = ev.target.value
      localStorage.setItem('langUser', lang)
      if (lang === 'EN') {
        router.replace('/')
      }
    }
    const isVideo = ref(false)
    let burger;
    let closeMenu;
    onMounted(() => {
      if (window.innerWidth > 900)
      {
          isVideo.value = true
      }
      let select = document.getElementById("langSelRu");
      select.selectedIndex = 1;
      burger = document.querySelector('#burgerRuHome')
      closeMenu = document.querySelector('#closeRuHome')
      burger.addEventListener('click', () => {
        classBurger.value = 'active'
      })
      closeMenu.addEventListener('click', () => {
        classBurger.value = ''
      })
      window.addEventListener('resize', function() {
        windowMatchMedia()
      }, true);
    })
	return {
		goScroll, scroll, classBurger, goToApps, isPopup, src1, src2, src3, src4, src5, src6, src7, src8, src9, goToLink, goLang, isVideo, isMobile, utm
	}
  },
  components: {
    IonContent,
    IonPage
  }
});
</script>

<style scoped>
</style>
