import { createApp } from 'vue'
// import './firebase'
import App from './App.vue'
import router from './router';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyB2OVDDudYNEMRrwXADE1vc47ATK8UJSMs",
  authDomain: "dutiap-project.firebaseapp.com",
  projectId: "dutiap-project",
  storageBucket: "dutiap-project.appspot.com",
  messagingSenderId: "754153687016",
  appId: "1:754153687016:web:b349f925d807ac45dc865d",
  measurementId: "G-47S42TSXDW"
};

// Initialize Firebase
console.log('Test1')
const appspa = initializeApp(firebaseConfig);
const analytics = getAnalytics(appspa);
import axios from 'axios'
console.log('Test2')
import { collection, addDoc, serverTimestamp, getFirestore } from "firebase/firestore"; 
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/main.css';
const db = getFirestore()
let userLang = navigator.language || navigator.userLanguage;
localStorage.setItem('lang', userLang)
localStorage.setItem('langUser', '')

function getLang() {
  let result = false
  const tempLang = localStorage.getItem('langUser')
  if (tempLang !== '') {
    if (tempLang === 'RU') {
      result = true
    }
  } else {
    if (userLang == 'ru-RU' || userLang.indexOf('ru') !== -1 || userLang.indexOf('RU') !== -1 || userLang.indexOf('Ru') !== -1) {
      result = true
    }
  }
  return result
}

function DetectDevice() {
  let isMobile = window.matchMedia || window.msMatchMedia;
  if(isMobile) {
    let match_mobile = isMobile("(pointer:coarse)");
    return match_mobile.matches;
  }
  return false;
}

router.beforeEach((to, from, next)=>{
  let pathname = window.location.pathname;
  switch (to.name) {
    case 'Bugs':
      window.location = "https://docs.google.com/forms/d/e/1FAIpQLScl3oyZRky6ydK472vs7YnQ8QeugYUbfhJ618hBJjsdD7KJgg/viewform?usp=sf_link"
      break;
    case 'Home':
      if (getLang()) {
        next({
          path: '/ru/',
          replace: true
        })
      } else {
        next()
      }
      break;
    case 'RuHome':
      next()
      break;
    case 'Support':
      if (getLang()) {
        next({
          path: '/ru/support',
          replace: true
        })
      } else {
        next();
      }
      break;
    case 'About':
      if (getLang()) {
        next({
          path: '/ru/about',
          replace: true
        })
      } else {
        next();
      }
      break;
    case 'Promo':
      next();
        break;
    case 'Privacy':
      if (getLang()) {
        next({
          path: '/ru/privacy',
          replace: true
        })
      } else {
        next();
      }
      break;
    case 'Terms':
      if (getLang()) {
        next({
          path: '/ru/terms',
          replace: true
        })
      } else {
        next();
      }
      break;
    case 'Music':
      if (getLang()) {
        next({
          path: '/ru/music',
          replace: true
        })
      } else {
        next()
      }
      break;
  
      default:
        if (to.name !== 'RuHome' && to.name !== 'RuSupport' && to.name !== 'RuAbout' && to.name !== 'RuPrivacy' && to.name !== 'RuTerms' && to.name !== 'RuMusic') {
          if (window.location.href.indexOf('utm_source') !== -1) {
            addDoc(collection(db, "advert"), {
              path: window.location.href,
              created: serverTimestamp()
            });
          }
          if (!DetectDevice()) {
            if (getLang()) {
              next({
                path: '/ru/',
                replace: true
              })
            } else {
              next({
                path: '/',
                replace: true
              })
            }
          } else {
            if (pathname.indexOf('@') !== -1) {
              let utm = ''
              let ppath = window.location.href
              const indexPath = ppath.indexOf('utm_source')
              if (indexPath !== -1) {
                utm = ppath.substring(indexPath + 11)
              }
              axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB2OVDDudYNEMRrwXADE1vc47ATK8UJSMs', {
              "longDynamicLink": "https://dutiapp.page.link/?link=https://dutiap.com/@" + pathname.substring(2) + "&apn=com.dutiapinc.dutiap&isi=1562504099&ibi=com.dutiapinc.ios&utm_source=" + utm
              })
              .then(function (response) {
                window.location = response.data.shortLink;
              })
              .catch(function (error) {
                console.log('error', error);
                next()
              });
            } else {
              // if (pathname.indexOf('bugs') !== -1) {
              //   window.location = "https://docs.google.com/forms/d/e/1FAIpQLScl3oyZRky6ydK472vs7YnQ8QeugYUbfhJ618hBJjsdD7KJgg/viewform?usp=sf_link"
              // }
              next({
                path: '/',
                replace: true
              })
            }
          }
        } else {
          next()
        }
      break;
  }
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(analytics);
  
router.isReady().then(() => {
  app.mount('#app');
});