import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import PromoPage from '../views/PromoPage.vue'
import RuHomePage from '../views/RuHomePage.vue'
import SupportPage from '../views/SupportPage.vue'
import RuSupportPage from '../views/RuSupportPage.vue'
import AboutPage from '../views/AboutPage.vue'
import RuAboutPage from '../views/RuAboutPage.vue'
import PrivacyPage from '../views/PrivacyPage.vue'
import RuPrivacyPage from '../views/RuPrivacyPage.vue'
import TermsPage from '../views/TermsPage.vue'
import RuTermsPage from '../views/RuTermsPage.vue'
import MusicPage from '../views/MusicPage.vue'
import RuMusicPage from '../views/RuMusicPage.vue'
// import axios from 'axios'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/promo',
    name: 'Promo',
    component: PromoPage
  },
  {
    path: '/bugs',
    name: 'Bugs',
    component: HomePage
  },
  {
    path: '/ru/',
    name: 'RuHome',
    component: RuHomePage
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: userLang == 'ru-RU' ? RuHomePage : HomePage
  // },
  {
    path: '/support',
    name: 'Support',
    component: SupportPage
  },
  {
    path: '/ru/support',
    name: 'RuSupport',
    component: RuSupportPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/ru/about',
    name: 'RuAbout',
    component: RuAboutPage
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage
  },
  {
    path: '/ru/privacy',
    name: 'RuPrivacy',
    component: RuPrivacyPage
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage
  },
  {
    path: '/ru/terms',
    name: 'RuTerms',
    component: RuTermsPage
  },
  {
    path: '/music',
    name: 'Music',
    component: MusicPage
  },
  {
    path: '/ru/music',
    name: 'RuMusic',
    component: RuMusicPage
  }
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'Home',
  //   component: HomePage
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
